<template>
    <div>
        <Tree ref="tree" :data="treeData" show-checkbox class="tree-div" :multiple="false" @on-check-change="onCheckChange"></Tree>
    </div>
</template>

<script>
export default {
    props: {
        custGlobalId: {
            type: String,
            default: () => {
                return ""
            },
        },
    },
    //import引入的组件需要注入到对象中才能使用
    components: {},
    data() {
        //这里存放数据
        return {
            treeData: [],
        }
    },
    //方法集合
    methods: {
        getTree(data) {
            if (data && data != "") {
                this.$get("/old/api/pc/menu/getPlatFormMenuTreeNonAdmin", {
                    custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                    orgCode: data,
                }).then(res => {
                    if (res.code == 200) {
                        this.treeData = res.dataList
                    } else {
                        this.$Message.error({
                            content: res.desc,
                            background: true,
                        })
                    }
                })
            }
        },

        onCheckChange(evt) {
            this.selected = evt.map(v => v.id)
        },

        /**
         * 获取选中的节点
         */
        getSelected() {
            return this.selected
        },

        /**
         * 循环所以节点清除选中
         */
        clearSelected() {
            const process = arr => {
                arr.forEach(v => {
                    v.selected = false
                    v.expand = false
                    v.checked = false
                    if (v.children && v.children.length) {
                        process(v.children)
                    }
                })
            }
            // 根层级开始处理
            process(this.treeData)
            // 已选中的清空
            this.selected = []
        },
    },
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {},
    watch: {
        custGlobalId: {
            handler(val, newVal) {
                if (val) {
                    this.getTree(val)
                }
            },
            immediate: true,
        },
    },
}
</script>
