<!--
用途：市、区、街道、社区的级联选择
传参： orgCode:'44', //默认为44,即广东省开始查起
       resetNum:0 , //默认为0，改变了就会触发清空的方法，一般用于重置使用，让resetNum自增1即可
暴露出去的方法： changeCasader ，选择了每一项之后的数据

-->

<template>
    <div>
        <Cascader transfer @on-change="changeCasder" :load-data="loadData" change-on-select :style="`width: ${width}px`" :data="CascaderList" v-model="CascaderId" :placeholder="orgCode == '44' ? '请选择市/区/街道/社区' : '请选择'"></Cascader>
    </div>
</template>

<script>
import Headway from "../utils/headway"
export default {
    props: {
        orgCode: {
            type: String,
            default: () => {
                return "44"
            },
        },
        resetNum: 0,
        width: 350,
        value: {
            type: Array,
            default: [],
        },
    },
    data() {
        return {
            // 存放选择的社区
            CascaderList: [],
            CascaderId: [],
        }
    },
    watch: {
        resetNum() {
            this.CascaderId = []
        },
        value: {
            async handler(val) {
                if (!this.initial) {
                    this.CascaderList = []
                    await this.getCascader()
                }

                if (val && val.length > 0) {
                    for (let i = 0; i < 3; i++) {
                        await new Headway(this, "level", {
                            condition: c => {
                                return c >= i
                            },
                        })
                            .on()
                            .then(_ => {
                                this.CascaderId.push(val[i])
                            })
                    }
                } else {
                    this.CascaderId = []
                }
            },
            immediate: true,
        },
    },
    created() {
        // this.getCascader()
    },
    methods: {
        // 获取级联
        getCascader() {
            return this.$get("/orgzz/pc/organization/selectCascadeDataScope", {
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                oemCode: parent.vue.oemInfo.oemCode,
                orgCode: this.orgCode,
                orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
            }).then(res => {
                if (res.code == 200 && res.dataList.length != 0) {
                    this.CascaderList = this.forMatterTree(res.dataList)
                    this.CascaderList.map(item => {
                        item.loading = false
                    })
                    this.level = 0
                    this.initial = true
                }
            })
        },
        changeCasder(val, item) {
            this.$emit("changeCasader", val)
        },
        forMatterTree(data) {
            for (var i = 0; i < data.length; i++) {
                data[i].label = data[i].orgName
                data[i].value = data[i].orgCode
                if (data[i].children && data[i].children != null && data[i].children.length > 0) {
                    this.forMatterTree(data[i].children)
                }
            }
            return data
        },
        loadData(item, callback) {
            item.loading = true
            let params = {
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                oemCode: parent.vue.oemInfo.oemCode,
                orgCode: item.orgCode,
                orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
            }
            this.$get("/orgzz/pc/organization/selectCascadeDataScope", {
                ...params,
            }).then(res => {
                if (res.code == 200 && res.dataList.length != 0) {
                    let arr = this.forMatterTree(res.dataList)
                    arr.map(items => {
                        if (items.orgLevel == 4) {
                            items.loading = false
                        } else if (items.orgLevel == 3) {
                            items.loading = false
                        }
                    })
                    item.loading = false
                    item.children = arr
                    callback(item.children)

                    this.level++
                } else {
                    callback([])
                }
            })
        },
    },
}
</script>

<style></style>
